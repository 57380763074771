import React, { useState } from "react"
import Layout from "../components/layout"
import About from '../components/about'
import SEO from '../components/seo'
import "twin.macro"

const AboutPage = (props) => {
	return <>
		<Layout>
			<SEO title="About"/>
			<About/>
		</Layout>
	</>
}

export default AboutPage